import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './pages/home/Home';
import UserData from './pages/userdata/UserData';
import Success from './pages/success/Success';
import Survey from './pages/survey/Survey';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-light-notifications/lib/main.css";
import './App.css';
import {NotificationContainer} from "react-light-notifications";

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/"
                           element={<Home/>}></Route>
                    <Route path="/counter"
                           element={<UserData/>}/>
                    <Route path="/success"
                           element={<Success/>}/>
                    <Route path="/survey"
                           element={<Survey/>}/>
                </Routes>
            </Router>
            <NotificationContainer/>
        </div>


    );
}

export default App;
