import React, {useEffect, useState} from "react";
import axioClient from "../../http-common";
import {Link, useNavigate} from "react-router-dom";
import {NotificationManager} from "react-light-notifications";

function Survey() {
    let navigate = useNavigate();
    const data = JSON.parse(localStorage.getItem(`brand`))
    const [input, setInput] = useState({
        app: ``,
        brand: 1,
        purchase: 1,
        other: ``,
        key: ``
    })
    useEffect(() => {
        if (typeof data == `undefined` || data == null) {
            window.location.href = `/`
            return
        }

        setInput({
            ...input,
            key: data.key,
            app: data.app,
        })
    }, [])


    const handleInput = (e) => {
        const {value, name} = e.target
        let v = value
        if (name != `other`)
            v = v * 1

        setInput({
            ...input,
            [name]: v
        })
    }

    const handleSubmit = () => {
        axioClient.post(`/survey/add`, input).then(resp => {
            navigate(`/success`);
        }).catch(err => {
            let dt = err.response.data
            if (typeof dt.message != `undefined`) {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: dt.message,
                });
            } else {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: 'ลองใหม่อีกครั้ง',
                });
            }
        })
    }

    return (
        <>
            <div className="pagewrapper">
                <div className="pagewrapper_header mb-3">
                    <img src={`/images/${data.imageHead}`} alt="" srcSet=""/>
                </div>
                <div className="h5">กรุณาตอบคำถามต่อไปนี้</div>
                <div className='px-4 text-left'>
                    <div className="form-group">
                        <label>1. ปัจจุบันคุณบริโถคสินค้าญี่ห้อนี้อยู่หรือไม่</label>
                        <div className="form-check">
                            <input className="form-check-input" name="brand" type="radio" defaultChecked={true} onClick={e => handleInput(e)} value="1" id="choice1"/>
                            <label className="form-check-label" htmlFor="choice1">
                                ใช่
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" name="brand" type="radio" onClick={e => handleInput(e)} value="0" id="choice2"/>
                            <label className="form-check-label" htmlFor="choice2">
                                ไม่ใช่
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>ถ้าไม่ใช่ ปัจจุบันบริโภคยี่ห้ออะไร</label>
                        <input type="text" name="other" disabled={input.brand == 1} onChange={e => handleInput(e)} className="form-control"/>
                    </div>
                    <div className="form-group mt-5">
                        <label>2. วันนี้คุณซื้อสินค้ากลับไปหรือไม่</label>
                        <div className="form-check">
                            <input className="form-check-input" name="purchase" type="radio" defaultChecked={true} onClick={e => handleInput(e)} value="1" id="choicea1"/>
                            <label className="form-check-label" htmlFor="choicea1">
                                ใช่
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" name="purchase" type="radio" onClick={e => handleInput(e)} value="0" id="choicea2"/>
                            <label className="form-check-label" htmlFor="choicea2">
                                ไม่ใช่
                            </label>
                        </div>
                    </div>
                </div>
                <button className='btn btn-lg btn-block px-4' onClick={handleSubmit} style={{backgroundColor: data?.color,}}>ส่งแบบสอบถาม</button>
            </div>
        </>
    )
}

export default Survey
