import './UserData.css';
import React, {useEffect, useState} from 'react';
import axioClient from "../../http-common";
import {monthTH} from "../../config/config";
import {Link} from "react-router-dom";

const UserData = () => {
    const date = new Date()
    const [counter, setCounter] = useState(0)
    const data = JSON.parse(localStorage.getItem(`brand`))

    useEffect(() => {
        if (typeof data == `undefined` || data == null) {
            window.location.href = `/`
            return
        }
        axioClient.get(`/survey/count`).then(resp => {
            setCounter(resp.data.count)
        })

    }, [])
    return (<>
        <div className="pagewrapper">
            <div className="pagewrapper_header">
                <img src={`/images/${data.imageHead}`} alt="" srcSet=""/>
            </div>
            <div className='content'>
                <h1>ข้อมูลของผู้บริโภค</h1>
                <p>วันที่</p>
                <div className="d-flex justify-content-center h4">
                    <span className="mr-2">{date.getDate()}</span>
                    <span className="mr-2">{monthTH[date.getMonth() + 1]}</span>
                    <span>{date.getFullYear() + 543}</span>
                </div>
                <p>คนที่</p>
                <h2>{counter}</h2>
                <Link to="/survey" className='btn btn-lg btn-block px-4' style={{backgroundColor: data?.color,}}>ทำแบบสอบถาม</Link>

            </div>
        </div>
    </>)

}
export default UserData
