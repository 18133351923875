import axios from "axios";

export const base_url = `https://nestle-product-switching-backend.whaleorganize.net/api`;

export const axioClient = axios.create({
    baseURL: base_url,
    // baseURL: "http://127.0.0.1:8000/api",
});

axioClient.interceptors.request.use(
    config => {
        // config.headers['Content-Type'] = 'text/plain';
        // config.headers['Accept'] = 'application/json';
        // config.withCredentials = true;
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        // config.headers['Access-Control-Allow-Credentials'] = 'true';
        //config.headers['Access-Control-Allow-Credentials'] = 'true';
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default axioClient;
