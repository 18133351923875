import {combineReducers} from "redux";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import SurveySlice from "./tools/survey";


const combinedReducer = combineReducers({
    Survey: SurveySlice.reducer,
})


const rootReducer = (state, action) => {
    if (action.type === "logout") {
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};


export default configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware({serializableCheck: false})],
    devTools: process.env.NODE_ENV !== "production",
});
