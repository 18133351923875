export const Brands = [
    {
        key: `nesvita`,
        name: `Nesvita`,
        image: `01-nesvita.png`,
        imageHead: `nesvita-head.png`,
        color: `#17b80d`,
    }, {
        key: `nescafe-bb`,
        name: `Nescafe Blend & Brew`,
        image: `02-nescafe.png`,
        imageHead: `02-nescafe.png`,
        color: `#dc0606`,
    }, {
        key: `nescafe-rc`,
        name: `Nescafe Red Cup`,
        image: `03-redcup.png`,
        imageHead: `03-redcup.png`,
        color: `#000`,
    }, {
        key: `nescafe-a`,
        name: `Nescafe Americano`,
        image: `04-americano.png`,
        imageHead: `04-americano.png`,
        color: `#000`,
    }, {
        key: `maggi`,
        name: `Maggi`,
        image: `05-maggi.png`,
        imageHead: `05-maggi.png`,
        color: `#d5d107`,
    }, {
        key: `milo`,
        name: `Milo`,
        image: `06-milo.png`,
        imageHead: `05-milo.png`,
        color: `#0c8012`,
    }, {
        key: `nestle-bearband`,
        name: `Nestle Bearband`,
        image: `07-bearband.png`,
        imageHead: `07-bearband.png`,
        color: `#d20c30`,
    }, {
        key: `nestle-mdee`,
        name: `Nestle Imdee`,
        image: `08-imdee.png`,
        imageHead: `08-imdee.png`,
        color: `#d20c30`,
    },
]


export const monthTH = {
    1 : `มกราคม`,
    2 : `กุมภาพันธ์`,
    3 : `มีนาคม`,
    4 : `เมษายน`,
    5 : `พฤษภาคม`,
    6 : `มิถุนายน`,
    7 : `กรกฏาคม`,
    8 : `สิงหาคม`,
    9 : `กันยายน`,
    10 : `ตุลาคม`,
    11 : `พฤศจิกายน`,
    12 : `ธันวาคม`,
}
