import {createSlice} from "@reduxjs/toolkit";


const SurveySlice = createSlice({
    name: `SurveySlice`,
    initialState: {
        brand: null,
    },
    reducers: {
        setData: (state, action) => {
            return Object.assign(state, {
                brand: action.payload.brand
            });
        },

    }
})

export default SurveySlice


export const SetSurveyBrand = (item) => {
    return (dispatch) => {
        dispatch(SurveySlice.actions.setData({
            brand: item
        }))
    }
}


