import '../success/Success.css'
import React, {useEffect, useState} from "react";
import axioClient from "../../http-common";

function Success() {
    const data = JSON.parse(localStorage.getItem(`brand`))
    useEffect(() => {
        if (typeof data == `undefined` || data == null) {
            window.location.href = `/`
            return
        }

    }, [])
    return (
        <>
            <div className="pagewrapper">
                <div className="pagewrapper_header">
                    <img src={`/images/${data.imageHead}`} alt="" srcSet=""/>
                </div>
                <div className="content">
                    <div className="p-3">
                        <div className="content-img">
                            <img src="/images/All-pack.png" alt="" srcSet=""/>
                        </div>
                        <h1>"ขอบคุณที่ให้ข้อมูล"</h1>
                        <p>พวกเราจพน้ำข้อมูลที่ได้ไรับไปพัฒนาผลิตภัณฑ์ให้ดียิ่งขึ้น</p>
                    </div>
                    <a href={`/?code=${data?.app}`} className="btn btn-lg btn-block" style={{backgroundColor: data?.color,}}>ออกจากหน้านี้</a>
                </div>


            </div>
        </>
    )
}

export default Success
