import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import '../home/Home.css'
import {Brands} from "../../config/config";
import {NotificationManager} from "react-light-notifications";

function Home() {
    let navigate = useNavigate();
    const [params, setParams] = useState("")
    const handleClick = (item) => {
        if (params == ``) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'กรุณาแสกน QR Code เพื่อเข้าใช้งานแบบสอบถาม',
            });
            return
        }
        item.app = params
        localStorage.setItem(`brand`, JSON.stringify(item))
        navigate(`/counter`);
    }

    useEffect(() => {
        const params = new URLSearchParams(
            window ? window.location.search : {}
        );
        const a = new Proxy(params, {
            get(target, prop) {
                return target.get(prop)
            },
        });

        const {code} = a;
        // alert(code)
        if (typeof code == `undefined` || code == `` || code == null) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'เข้าใช้งานไม่ถูกต้อง',
            });
        } else {
            setParams(code)
        }

    }, [])


    return (
        <>
            <div className="pagewrapper">

                <div className="p-4">
                    <div className="mb-4">
                        <img src="/images/main-logo.png"/>
                    </div>

                    <div className="h6 mb-3 font-weight-bold"><u>เลือกแบรนด์ที่ต้องการทำแบบสอบถาม</u></div>
                    <div className="row">
                        {Brands.map((v, i) => (
                            <div key={i} className="col-6 mb-3">
                                <a onClick={() => handleClick(v)}><img src={`images/${v.image}`} className="w-100" alt={v.name}/></a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
